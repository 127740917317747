import styled from 'styled-components';
import { height as styledHeight, space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import media from 'styled-media-query';
import Box from '@firsttable/web-components/atoms/Box';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import searchBarTheme from './theme';

const searchBarSize = 52;

const SearchBtn = styled(Box)`
  top: 0;
  left: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: ${searchBarSize}px;
  z-index: 1;
  ${media.greaterThan(bp.s)`
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -${searchBarSize / 2}px;
    justify-content: flex-end;
  `};
`;
const SearchBtnText = styled(Box)`
  top: 0;
  left: 40px;
  display: none;
  align-items: center;
  width: 200px;
  height: ${searchBarSize}px;
  ${media.greaterThan(bp.m)`
    display: flex;
    position: absolute;
    top: 50%;
    left: 52px;
    margin-top: -${searchBarSize / 2}px;
  `};
`;

const RegionFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 5px;
  ${media.greaterThan('1160px')`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    justify-content: center;
    height: ${({ height }) => height.desktop};
  `};
`;

const SearchBarWrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;

  .${searchBarTheme.container} {
    position: relative;
    width: 100%;
  }
  .${searchBarTheme.containerOpen} {}
  .${searchBarTheme.input} {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    ${({ height, heightbar, ...rest }) => styledHeight({
    height: heightbar, ...rest,
  })};
    ${({ paddingbar, ...rest }) => space({
    p: paddingbar,
    ...rest,
  })};
    border: 0;
    outline: 0;
    background: #ffffff;
    line-height: normal;
    -webkit-appearance: none;
    border-radius: 2px;
  }
  .${searchBarTheme.inputOpen} {}
  .${searchBarTheme.inputFocused} {}
  .${searchBarTheme.suggestionsContainer} {
      position: absolute;
      z-index: 2;
      top: 100%;
      left: -16px;
      right: -16px;
      max-height: 80vh;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      ${(props) => themeGet('colorStyles')(props).dropdown};
      ${media.greaterThan(bp.s)`
        top: calc(100% + 2px);
        left: 0;
        right: 0;
        max-height: 500px;
        border-radius: 2px;
      `};
  }
  .${searchBarTheme.suggestionsContainerOpen} {}
  .${searchBarTheme.suggestionsList} {
    margin: 0;
    padding: 0;
  }
  .${searchBarTheme.suggestion} {}
  .${searchBarTheme.suggestionFirst} {}
  .${searchBarTheme.suggestionHighlighted} {
    background-color: ${themeGet('colors.grayscale.300')};
  }
  .${searchBarTheme.sectionContainer} {}
  .${searchBarTheme.sectionContainerFirst} {}
  .${searchBarTheme.sectionTitle} {}

  .ais-InstantSearch__root {
    width: 100%;
    display: block;
  }

  .c-main-nav__item--search--closed {
    .ais-InstantSearch__root {
      display: none;
    }
  }
}`;

export {
  SearchBtn, SearchBtnText, RegionFilterWrapper, SearchBarWrapper,
};
