import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';
import {
  Box,
  Col,
  Container,
  Flex,
  Icon,
  Link,
  Row,
} from '@firsttable/web-components/atoms';
import RegionsModal from '@firsttable/web-components/organisms/RegionsModal';
import spaces from '@firsttable/web-theme/styledSpaces';
import { isTablet } from 'react-device-detect';
import { flowRight as compose } from 'lodash';
import UserMenu from './UserMenu';
import SearchBar from './SearchBar';
import NavLinkComponent from '../../atoms/NavLink';
import {
  RegionFilterWrapper,
  SearchBarWrapper,
  SearchBtn,
  SearchBtnText,
} from './styles';
import { isMinorSite } from '../../../helpers/data';
import { withCitySearchFilters } from '../../../hocs';

const DesktopRegionFilterWrapper = styled(Box)`
  display: block;
  ${media.lessThan('1160px')`
    display: none;
  `};
`;

const MobileMenuWrapper = styled(Box)`
  display: none;
  ${media.lessThan('1160px')`
    display: block;
  `};
`;

const DelayedUserMenu = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    setShowMenu(true);
  }, []);
  if (!showMenu) {
    return null;
  }
  return <UserMenu {...props} />;
};

const PureMenu = ({
  onlyLogo,
  transparent,
  logOut,
  auth,
  modal,
  navigate,
  groupedRegions,
  regionsList,
  toggleSearchBar,
  showSearch,
  restaurantSearch,
  menuTitle,
  country,
  ...props
}) => {
  const height = {
    desktop: transparent ? '116px' : '84px',
    mobile: '52px',
  };
  const menuColour = transparent ? 'white' : 'brand.blue';
  return (
    <Box
      {...spaces(props)}
      bg={['transparent', transparent && 'transparent']}
      position="relative"
      zIndex={5}
      boxShadow={[
        '0px 30px 20px rgba(0, 0, 0, 0.03)',
        '0px 30px 60px rgba(0, 0, 0, 0.03)',
      ]}
    >
      <Container maxWidth="100%">
        <Row>
          <Col>
            <Flex
              justifyContent={onlyLogo ? 'center' : null}
              position="relative"
            >
              <Flex
                height={[height.mobile, height.desktop]}
                alignItems="center"
                position="relative"
                zIndex={1}
                flex={onlyLogo ? 0 : [1, 1, 0]}
              >
                <Link
                  to="/"
                  NavComponent={NavLinkComponent}
                  display="flex"
                  height="100%"
                  aria-label="go home"
                  color={menuColour}
                >
                  <DesktopRegionFilterWrapper alignSelf="center">
                    <Icon name="logo" color={null} top="0px" />
                  </DesktopRegionFilterWrapper>
                  <MobileMenuWrapper alignSelf="center">
                    <Icon name="bird-logo" color={null} top="2px" />
                  </MobileMenuWrapper>
                </Link>
                {!onlyLogo && (
                  <MobileMenuWrapper>
                    <RegionFilterWrapper height={height}>
                      <RegionsModal
                        role="button"
                        aria-label="region"
                        color={transparent ? 'white' : 'brand.blue'}
                        heights={height}
                        zIndex={!showSearch ? 1 : null}
                        pl={5}
                        useButton={false}
                        modalNumber={2}
                        groupedRegions={groupedRegions}
                        regionsList={regionsList}
                        NavLinkComponent={NavLinkComponent}
                      >
                        <Box ml="3px" mr="6px" fontSize="15px">
                          {menuTitle || 'Region'}
                        </Box>
                        <Icon name="s-arrow-down" color="inherit" />
                      </RegionsModal>
                    </RegionFilterWrapper>
                  </MobileMenuWrapper>
                )}
              </Flex>
              {!onlyLogo && (
                <>
                  <DesktopRegionFilterWrapper>
                    <RegionFilterWrapper height={height}>
                      {!transparent ? (
                        <RegionsModal
                          role="button"
                          aria-label="region"
                          color="brand.blue"
                          heights={height}
                          zIndex={!showSearch ? 1 : null}
                          useButton={false}
                          modalNumber={1}
                          groupedRegions={groupedRegions}
                          regionsList={regionsList}
                          NavLinkComponent={NavLinkComponent}
                        >
                          <Box mr="6px">Region</Box>
                          <Icon name="s-arrow-down" color="inherit" />
                        </RegionsModal>
                      ) : (
                        <RegionsModal
                          groupedRegions={groupedRegions}
                          regionsList={regionsList}
                          zIndex={!showSearch ? 1 : null}
                          useButton
                          NavLinkComponent={NavLinkComponent}
                        >
                          Regions
                          <Icon
                            ml="5px"
                            mt="-3px"
                            name="s-arrow-down"
                            color="inherit"
                          />
                        </RegionsModal>
                      )}
                    </RegionFilterWrapper>
                  </DesktopRegionFilterWrapper>
                  <Box
                    position={
                      showSearch
                        ? ['absolute', 'absolute', 'relative']
                        : 'relative'
                    }
                    zIndex={showSearch ? 1 : 0}
                    mx={['-16px', '0', 'l']}
                    px={['8px', '0']}
                    flex={[0, 3]}
                    top={0}
                    left={0}
                    right={0}
                  >
                    {showSearch && (
                      <SearchBarWrapper
                        height={[height.mobile, height.desktop]}
                        heightbar={[
                          height.mobile,
                          !transparent && height.desktop,
                        ]}
                        paddingbar={['0 0 0 35px', `0 20px 0 ${height.mobile}`]}
                      >
                        <SearchBar navigate={navigate} country={country} />
                      </SearchBarWrapper>
                    )}
                    {!isMinorSite && (
                      <>
                        <SearchBtn
                          role="button"
                          aria-label="search"
                          onClick={() => toggleSearchBar()}
                          position={showSearch ? 'absolute' : null}
                          ml={['6px', '']}
                          mr={['14px', '']}
                        >
                          <Icon
                            name="m-search"
                            color={showSearch ? 'grayscale.600' : menuColour}
                          />
                        </SearchBtn>
                        <SearchBtnText
                          role="button"
                          aria-label="search"
                          onClick={() => toggleSearchBar()}
                          color={showSearch ? 'grayscale.600' : menuColour}
                        >
                          Search restaurants
                        </SearchBtnText>
                      </>
                    )}
                  </Box>
                  <Flex
                    position="relative"
                    ml={isTablet && isMinorSite ? 10 : null}
                  >
                    <DelayedUserMenu
                      userData={{ ...auth }}
                      isLoggedIn={auth.isLoggedIn}
                      transparent={transparent}
                      height={height}
                      logOut={logOut}
                      modal={modal}
                    />
                  </Flex>
                </>
              )}
            </Flex>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

PureMenu.propTypes = {
  onlyLogo: PropTypes.bool,
  menuTitle: PropTypes.string,
  transparent: PropTypes.bool,
  logOut: PropTypes.func,
  auth: PropTypes.object,
  modal: PropTypes.object,
  groupedRegions: PropTypes.shape(),
  restaurantSearch: PropTypes.shape(),
  regionsList: PropTypes.shape(),
  navigate: PropTypes.func,
  showSearch: PropTypes.bool.isRequired,
  toggleSearchBar: PropTypes.func.isRequired,
  country: PropTypes.string,
};

PureMenu.defaultProps = {
  onlyLogo: false,
  menuTitle: '',
  transparent: false,
  navigate: (f) => f,
  country: 'New Zealand',
};

export default compose(withCitySearchFilters)(PureMenu);
