import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PureFooter from '@firsttable/web-components/organisms/Footer';
import { isMinorSite } from '../../../helpers/data';
import NavLink from '../../atoms/NavLink';
import NavButton from '../../atoms/NavButton';

const Footer = () => {
  const { siteConfig, footerLinks } = useStaticQuery(graphql`
    query FooterQuery {
      siteConfig {
        id
        foreignId
        facebookURL
        instagramURL
        restaurantCount
        socialCount
        dinerCount
      }
      footerLinks: allPage(filter: { slug: {nin: ["/partners/", "/about-us/our-story/", "/about-us/mission/", "/first-table-partners/", "/restaurateurs/"]}}) {
        edges {
          node {
            id
            className
            slug
            menuTitle
            showOnFrontend
            frontendTemplate
          }
        }
      }
    }
  `);
  return (
    <PureFooter
      siteConfig={siteConfig}
      footerLinks={footerLinks}
      isMinorSite={isMinorSite}
      NavLinkComponent={NavLink}
      NavButtonComponent={NavButton}
    />
  );
};

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
