import React, { useContext, useState } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import onClickOutside from 'react-onclickoutside';
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext';
import PureMenu from './PureMenu';
import { countryISOCode } from '../../../helpers/data';

const Menu = (props) => {
  const auth = useContext(AuthContext);
  const modal = useContext(ModalContext);
  const { allCountryState, regionList, siteConfig } = useStaticQuery(graphql`
    query RegionsCSSListQuery {
      allCountryState {
        edges {
          node {
            id
            title
            cities {
              id
              menuTitle
              slug
              title
            }
          }
        }
      }
      regionList: allRegionsJson {
        edges {
          node {
            countryCode
            regionLinks {
              slug
              title
            }
          }
        }
      }
      siteConfig {
        country
      }
    }
  `);

  const countryCode = countryISOCode();
  const filteredCountryList = regionList.edges.filter(
    ({ node }) => node.countryCode === countryCode,
  );
  const [list] = filteredCountryList;
  const { node: cities } = list;

  const [showSearch, setShowSearchState] = useState(false);

  // use in-conjunction with handle click outside HOC
  Menu.handleClickOutside = () => setShowSearchState(false);
  const toggleSearchBar = () => setShowSearchState(!showSearch);

  return (
    <PureMenu
      {...props}
      auth={auth}
      modal={modal}
      navigate={navigate}
      groupedRegions={allCountryState}
      regionsList={cities}
      showSearch={showSearch}
      toggleSearchBar={toggleSearchBar}
      country={siteConfig.country}
    />
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Menu.handleClickOutside,
};

export default onClickOutside(Menu, clickOutsideConfig);
