export default {
  container: 'search-bar__container',
  containerOpen: 'search-bar__container--open',
  input: 'search-bar__input',
  inputOpen: 'search-bar__input--open',
  inputFocused: 'search-bar__input--focused',
  suggestionsContainer: 'search-bar__suggestions-container',
  suggestionsContainerOpen: 'search-bar__suggestions-container--open',
  suggestionsList: 'search-bar__suggestions-list',
  suggestion: 'search-bar__suggestion',
  suggestionFirst: 'search-bar__suggestion--first',
  suggestionHighlighted: 'search-bar__suggestion--highlighted',
  sectionContainer: 'search-bar__section-container',
  sectionContainerFirst: 'search-bar__section-container--first',
  sectionTitle: 'search-bar__section-title',
};
