import React from 'react';
import styled from 'styled-components';
import { Link as NavLink } from 'gatsby';
import { styles } from '@firsttable/web-components/atoms/Button';

const StyledNavLink = styled(NavLink)`
  ${styles};
`;

const NavButton = (props) => (
  <StyledNavLink {...props} />
);

export default NavButton;
