import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import onClickOutside from 'react-onclickoutside';
import Box from '@firsttable/web-components/atoms/Box';
import Flex from '@firsttable/web-components/atoms/Flex';
import Icon from '@firsttable/web-components/atoms/Icon';
import Button from '@firsttable/web-components/atoms/Button';
import Link from '@firsttable/web-components/atoms/Link';
import Text from '@firsttable/web-components/atoms/Text';
import Avatar from '@firsttable/web-components/atoms/Avatar';
import {
  formOpenEvent,
  getUser,
  hasPermission,
} from '@firsttable/functions';
import SignUp from '../Auth/SignUp';
import NavLinkComponent from '../../atoms/NavLink';
import NavButtonComponent from '../../atoms/NavButton';

const UserMenuStyled = styled(Box)`
  position: relative;
  justify-content: center;
`;
const Dropdown = styled(Box)`
  z-index: 1;
  position: absolute;
  right: 0;
  width: 240px;
  border-radius: 2px;
  padding: 8px 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  a,
  span {
    display: block;
    padding: 8px 20px;
    &:hover {
      background: ${themeGet('colors.brand.blue')};
      color: #fff;
    }
  }
  .last-item {
    margin: 8px 0 0;
    padding-top: 8px;
    border-top: 1px solid ${themeGet('colors.grayscale.300')};
  }
  .separated-item {
    margin: 8px 0;
    padding: 8px 0;
    border: 1px solid ${themeGet('colors.grayscale.300')};
    border-left: 0;
    border-right: 0;
  }
`;

const EmailText = styled(Text)`
  text-overflow: ellipsis;
`;

// const UserMenuWrap = onClickOutside(props => <Fragment>{props.children}</Fragment>, {
//   handleClickOutside: e => () => e.props.handleClickOutside(),
// });
const ADMIN_URL = process.env.GATSBY_MANAGE_URL;
const CMS_URL = process.env.GATSBY_CMS_URL || process.env.GATSBY_MANAGE_URL;

const UserMenu = ({ height, transparent, isLoggedIn, userData, modal }) => {
  const [menuState, setMenuState] = useState(false);

  // use in-conjunction with handle click outside HOC
  UserMenu.handleClickOutside = () => setMenuState(false);
  const toggleDropdown = () => setMenuState(!menuState);

  const user = getUser();

  const isUserLoggedIn = isLoggedIn();

  // const { menuState } = this.state;
  if (!isUserLoggedIn) {
    return (
      <>
        <Box
          display={['flex', 'none']}
          alignItems="center"
          justifyContent="center"
          onClick={() => toggleDropdown()}
          role="button"
          aria-label="login"
        >
          <Icon name="m-more-v" color={transparent ? 'white' : 'brand.blue'} />
          <Dropdown
            display={menuState ? 'block' : 'none'}
            top={['60px', '92px']}
            colors="dropdown"
          >
            <ul>
              <li>
                <Link
                  NavComponent={NavLinkComponent}
                  to="/frequently-asked-questions/"
                >
                  FAQs
                </Link>
              </li>
              <li>
                <Link NavComponent={NavLinkComponent} to="/our-story/">
                  Our Story
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    modal.showModalFunc('login');
                    formOpenEvent({
                      action: 'Login Form - Open',
                      label: '',
                    });
                  }}
                >
                  Login
                </Link>
              </li>
              <li>
                <Link
                  color="brand.red"
                  onClick={() => {
                    modal.showModalFunc(<SignUp isModal />, {
                      type: 'signUp',
                    });
                    formOpenEvent({
                      action: 'Sign-up Form - Open',
                      label: '',
                    });
                  }}
                >
                  Sign Up
                </Link>
              </li>
              <li>
                <Link NavComponent={NavLinkComponent} to="/restaurants/">
                  For Restaurateurs
                </Link>
              </li>
            </ul>
          </Dropdown>
        </Box>
        <Box display={['none', 'flex']} alignItems="center">
          <Link
            mr="m"
            to="/frequently-asked-questions/"
            NavComponent={NavLinkComponent}
            color={transparent ? 'white' : 'brand.blue'}
            display="flex"
            height="100%"
          >
            <Box
              alignSelf="center"
              fontSize={[
                null,
                `${themeGet('fontSizes.xs')}px`,
                `${themeGet('fontSizes.m')}px`,
              ]}
            >
              FAQs
            </Box>
          </Link>
          <Link
            mr="m"
            to="/our-story/"
            NavComponent={NavLinkComponent}
            color={transparent ? 'white' : 'brand.blue'}
            display="flex"
            height="100%"
          >
            <Box
              alignSelf="center"
              fontSize={[
                null,
                `${themeGet('fontSizes.xs')}px`,
                `${themeGet('fontSizes.m')}px`,
              ]}
            >
              Our Story
            </Box>
          </Link>
          <Link
            mr="m"
            onClick={() => {
              modal.showModalFunc(<SignUp isModal />, { type: 'signUp' });
              formOpenEvent({
                action: 'Sign-up Form - Open',
                label: '',
              });
            }}
            color="brand.red"
            display="flex"
            height="100%"
            data-testid="signUp__button"
            as="a"
            tabIndex="-1"
          >
            <Box
              alignSelf="center"
              fontSize={[
                null,
                css`
                  ${themeGet('fontSizes.xs')}px
                `,
                css`
                  ${themeGet('fontSizes.m')}px
                `,
              ]}
            >
              Sign Up
            </Box>
          </Link>
          <Link
            mr="m"
            onClick={() => {
              modal.showModalFunc('login');
              formOpenEvent({
                action: 'Login Form - Open',
                label: '',
              });
            }}
            color={transparent ? 'white' : 'brand.blue'}
            display="flex"
            height="100%"
            data-testid="login__button"
            as="a"
            tabIndex="-1"
          >
            <Box
              alignSelf="center"
              fontSize={[
                null,
                css`
                  ${themeGet('fontSizes.xs')}px
                `,
                css`
                  ${themeGet('fontSizes.m')}px
                `,
              ]}
            >
              Login
            </Box>
          </Link>
          <Button
            NavComponent={NavButtonComponent}
            to="/restaurants/"
            kind="outline"
            size="s"
          >
            For Restaurateurs
          </Button>
        </Box>
      </>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <Link
        display={['none', 'flex']}
        mr="m"
        to="/frequently-asked-questions/"
        NavComponent={NavLinkComponent}
        color={transparent ? 'white' : 'brand.blue'}
        height="100%"
      >
        <Box
          alignSelf="center"
          fontSize={[
            null,
            `${themeGet('fontSizes.xs')}px`,
            `${themeGet('fontSizes.m')}px`,
          ]}
        >
          FAQs
        </Box>
      </Link>
      <Link
        display={['none', 'flex']}
        mr="l"
        to="/our-story/"
        NavComponent={NavLinkComponent}
        color={transparent ? 'white' : 'brand.blue'}
        height="100%"
      >
        <Box
          alignSelf="center"
          fontSize={[
            null,
            `${themeGet('fontSizes.xs')}px`,
            `${themeGet('fontSizes.m')}px`,
          ]}
        >
          Our Story
        </Box>
      </Link>
      <UserMenuStyled
        as="span"
        borderLeft="1px solid transparent"
        borderRight="1px solid transparent"
        borderColor={[null, transparent ? null : 'grayscale.300']}
        pr={3}
        height={[height.mobile, height.desktop]}
        width={[null, '95px']}
        data-testid="login__avatar"
      >
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          role="button"
          aria-label="toggle"
          onClick={() => toggleDropdown()}
          textAlign="center"
        >
          <Box>
            {user.avatarURL ? (
              <Box
                width={['32px', '36px']}
                height={['32px', '36px']}
                borderRadius="50%"
                background={`${
                  transparent ? 'white' : '#265682'
                } url(${user.avatarURL
                  .replace(/=36/gim, '=100')
                  .replace(/36x36/, '100x100')}) no-repeat center center`}
                backgroundSize="cover"
                boxShadow="0 0 0 2px #EBEBEB"
              />
            ) : (
              <Avatar color="brand.red" width="36px" height="36px" />
            )}
          </Box>
        </Flex>
        <Dropdown
          display={menuState ? 'block' : 'none'}
          top={['60px', '92px']}
          colors="dropdown"
        >
          <ul>
            <li>
              <Link to="/profile/" NavComponent={NavLinkComponent}>
                <Text mb="-5px" fontWeight="bold">
                  {user.firstName} {user.surname}
                </Text>
                <EmailText
                  m={0}
                  fontSize="xs"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {user.email}
                </EmailText>
              </Link>
            </li>
            {hasPermission(['ADMIN', 'RESTAURANT_ADMINISTRATION']) && (
              <li className="separated-item">
                <Link href={`${ADMIN_URL}manage/`}>Restaurant Admin</Link>
              </li>
            )}
            <li>
              <Link
                NavComponent={NavLinkComponent}
                to="/frequently-asked-questions/"
              >
                FAQs
              </Link>
            </li>
            <li>
              <Link NavComponent={NavLinkComponent} to="/profile/">
                Profile
              </Link>
            </li>
            <li>
              <Link
                NavComponent={NavLinkComponent}
                to="/profile/account-balance/"
              >
                Account Balance
              </Link>
            </li>
            <li>
              <Link NavComponent={NavLinkComponent} to="/profile/reservations/">
                Reservations
              </Link>
            </li>
            <li>
              <Link
                NavComponent={NavLinkComponent}
                to="/profile/subscriptions/"
              >
                Subscriptions
              </Link>
            </li>
            {hasPermission('PROMO_CODE') && (
              <li>
                <Link
                  NavComponent={NavLinkComponent}
                  to="/profile/promo-codes/"
                >
                  Promo Code
                </Link>
              </li>
            )}
            {hasPermission(['CMS_ACCESS_LeftAndMain', 'ADMIN']) && (
              <li>
                <Link href={`${CMS_URL}admin/`}>CMS</Link>
              </li>
            )}
            {hasPermission(['CRM_ADMINISTRATION', 'ADMIN']) && (
              <li>
                <Link href={`${CMS_URL}GmailCRM/`}>GMail CRM</Link>
              </li>
            )}
            {hasPermission(['SOCIAL_ADMIN', 'ADMIN']) && (
              <li>
                <Link href={`${ADMIN_URL}social/`}>Social Admin</Link>
              </li>
            )}
            {hasPermission(['SALES_ADMIN', 'ADMIN']) && (
              <li>
                <Link href={`${ADMIN_URL}sales/`}>Sales Admin</Link>
              </li>
            )}
            {hasPermission(['REVIEWS_ADMIN', 'ADMIN']) && (
              <li>
                <Link href={`${ADMIN_URL}reviews/`}>Reviews Admin</Link>
              </li>
            )}
            <li className="last-item">
              <Link // eslint-disable-line
                onClick={() => userData.logOut()}
                data-testid="login__signout"
                as="a"
                tabIndex="-1"
              >
                Sign out
              </Link>
            </li>
          </ul>
        </Dropdown>
      </UserMenuStyled>
    </Box>
  );
};

UserMenu.propTypes = {
  height: PropTypes.object,
  transparent: PropTypes.bool,
  isLoggedIn: PropTypes.func.isRequired,
  userData: PropTypes.object,
  modal: PropTypes.object.isRequired,
};

UserMenu.defaultProps = {
  userData: {},
};

const clickOutsideConfig = {
  handleClickOutside: () => UserMenu.handleClickOutside,
};

export default onClickOutside(UserMenu, clickOutsideConfig);
