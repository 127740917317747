import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  InstantSearch,
  Configure,
  connectAutoComplete,
  connectHighlight,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import Autosuggest from 'react-autosuggest';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Box, Text, Icon } from '@firsttable/web-components/atoms';
import { awsImage } from '@firsttable/functions';
import theme from './theme';

const searchClient = algoliasearch(
  '33J5IFIRO1',
  '237a2855ea397bd467cba3cc7b307581',
);

const SearchBar = ({ showSearch, navigate, ...props }) => (
  <InstantSearch
    searchClient={searchClient}
    indexName={`restaurants_${process.env.GATSBY_SITE_ID}`}
  >
    <Configure filters="NOT status:Cancelled AND NOT status:Closed AND NOT status:'Ready To Go Live' AND NOT status:Pending AND NOT status:'Pending Approval' AND NOT status:'Lost Before Launch'" />
    <AutoComplete showSearch={showSearch} navigate={navigate} {...props} />
  </InstantSearch>
);

SearchBar.propTypes = {
  showSearch: PropTypes.bool,
  navigate: PropTypes.func,
};
// <Link to={hit.slug}>{hit.title}</Link>

const SearchBarItem = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;

  mark {
    background-color: ${themeGet('colors.warning.light')};
    color: ${themeGet('colors.warning.dark')};
  }
`;

const CustomHighlight = connectHighlight(
  ({ highlight, attribute, hit, country }) => {
    const parsedHit = highlight({
      attribute,
      hit,
      highlightProperty: '_highlightResult',
    });
    const highlightedHits = parsedHit.map((part, index) => {
      if (part.isHighlighted) return <mark key={index}>{part.value}</mark>;
      return part.value;
    });

    const { location, type, logo, base64Logo } = hit;
    const isRestaurant = type.toLowerCase() === 'restaurant';
    return (
      <SearchBarItem>
        <Box mr="15px" width="44px" textAlign="center">
          {isRestaurant ? (
            (logo || base64Logo) && (
              <img
                src={base64Logo || awsImage(logo, 60, 60)}
                alt=""
                className="img-fluid rounded-circle"
                style={{ maxWidth: 36 }}
              />
            )
          ) : (
            <Icon name="m-map-marker" />
          )}
        </Box>
        <Box flex={1}>
          <Text m={0}>{highlightedHits}</Text>
          <Box display={['block', null, 'flex']}>
            <Text fontSize="s" mb={0} color="grayscale.600">
              {isRestaurant ? location : <span>{country}</span>}
            </Text>
          </Box>
        </Box>
      </SearchBarItem>
    );
  },
);

class SearchAutoSuggest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
      isFocused: false,
      value: '',
      hits: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpened, hits } = this.state;
    const { showSearch } = this.props;
    const { hits: prevHits } = prevProps;
    // set input to open and focus
    if (showSearch && !isOpened) {
      this.setState({ isOpened: true });
      this.toggleFocusState(true);
    } else if (!showSearch && isOpened) {
      this.setState({ isOpened: false });
    }
    if (hits !== prevHits) {
      this.setState({ hits: prevHits });
    }
  }

  storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  toggleFocusState(state) {
    this.setState({ isFocused: state });
    if (state) {
      this.input.focus();
    }
  }

  // see https://codepen.io/mthuret/pen/VWbgxZ?editors=0011
  render() {
    const { refine, navigate, country } = this.props;
    const { value, hits } = this.state;

    return (
      <Autosuggest
        suggestions={hits}
        multiSection={false}
        onSuggestionsFetchRequested={({ value: suggestedValue }) =>
          refine(suggestedValue)
        }
        onSuggestionsClearRequested={() => this.setState({ hits: [] })}
        getSuggestionValue={(hit) => hit.title}
        renderSuggestion={(hit) => (
          <CustomHighlight attribute="title" hit={hit} country={country} />
        )}
        onSuggestionSelected={(event, { suggestionIndex }) => {
          const selectedRestaurant = hits[suggestionIndex];
          if (selectedRestaurant.slug) {
            navigate(selectedRestaurant.slug); // redirect on selection
          }
        }}
        inputProps={{
          placeholder: 'Search restaurants',
          value,
          onChange: (event, { newValue }) => {
            this.setState({ value: newValue });
          },
          autoFocus: true,
        }}
        ref={this.storeInputReference}
        theme={theme}
      />
    );
  }
}
SearchAutoSuggest.propTypes = {
  refine: PropTypes.func,
  navigate: PropTypes.func,
  country: PropTypes.string,
  showSearch: PropTypes.bool,
  hits: PropTypes.array,
};

const AutoComplete = connectAutoComplete(SearchAutoSuggest);

export default SearchBar;
